
import { mapActions, mapState } from 'pinia'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'
import { usePopMetricsStore } from "~/store/pops/metrics";
import { useBaseOnboardingStore } from '~/store/onboardings/baseOnboarding'
import { useRelayPlatformStore } from '~/store/relayPlatform'
import SidebarButton from "~/components/Layout/SidebarButton.vue";
import { useSmartrmailEmbeddedStore } from "~/store/smartrmail/embedded";

export default {
    props: {
        loading: {
            type: Boolean,
            default: true,
        }
    },
    data() {
      return {
        showSubmenu: false,
      }
    },
    components: {
      SidebarButton,
      Logo: ()=> import('~/static/icons/brand/logo-pop.svg'),
    },
    methods: {
      ...mapActions(useBaseOnboardingStore, ['startOnboarding']),
      ...mapActions(useSmartrmailEmbeddedStore, {
        setSmartrmailReAuthModalOpen: 'setReAuthModalOpen',
      }),
      onSmartmailClickMenuClick() {
        if(this.forceSmartrmailReAuth) {
          this.setSmartrmailReAuthModalOpen(true)
        } else {
          this.$router.push('/smartrmail/dashboard')
        }
      },
      onRelayPersonaClickMenuClick() {
        if(this.isRelayOnboardingStatusApprovedLive) {
          this.$router.push('/relay-persona/workflows')
        } else {
          this.$router.push('/relay-persona/')
        }
      }
    },
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
        ...mapState(usePricingStore, {
            maximumSessions: s => s.maximumSessions,
            limitReached: s => s.limitReached,
        }),
        ...mapState(usePopMetricsStore, [
          'overviewConversionsTotals',
          'overviewConversionsTotalsStrings'
        ]),
        ...mapState(useRelayPlatformStore, ['isRelayOnboardingStatusApprovedLive', 'isRelayOnboardingStatusNotLive']),
        ...mapState(useBaseOnboardingStore, ['shopOnboardings']),
        ...mapState(useSmartrmailEmbeddedStore, {
          forceSmartrmailReAuth: "isReAuthRequired",
        }),
        addToCartUpsellsOnboarding(){
          return this.shopOnboardings.find((onboarding) => onboarding.type === 'add_to_cart_upsells')
        },
        discountPopsOnboarding(){
          return this.shopOnboardings.find((onboarding) => onboarding.type === 'discount_pops')
        },
        newCustomerOnboarding(){
          return this.shopOnboardings.find((onboarding) => onboarding.type === 'new_customer')
        },
        unlimited() {
            return this.currentSite.pricing_plan?.unlimited
        },
        hasAtLeast5Conversions() {
            return this.overviewConversionsTotals.total_count >= 5
        },
        showRelayPersonaSubmenu() {
          return this.$route.path.startsWith('/relay-persona');
        },
        showSmartrmailSubmenu() {
          return this.$route.path.startsWith('/smartrmail');
        },
    },
}
