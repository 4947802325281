
import { mapActions, mapState } from "pinia";
import { useSiteStore } from "~/store/site";
import { useSmartrmailEmbeddedStore } from "~/store/smartrmail/embedded";

export default {
  name: 'SmartrmailReAuthModal',
  methods: {
    ...mapActions(useSiteStore, ["reAuthSiteShop"]),
    ...mapActions(useSmartrmailEmbeddedStore, ["setReAuthModalOpen"]),
    handleDismissReAuth() {
      this.setReAuthModalOpen(false);
    },
    handleAcceptReAuth() {
      this.reAuthSiteShop()
    }
  },
  computed: {
    ...mapState(useSmartrmailEmbeddedStore, ["reAuthModalOpen"]),
  }
}
