import { render, staticRenderFns } from "./default.vue?vue&type=template&id=9964e08e&scoped=true&lang=pug"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=9964e08e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9964e08e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toasts: require('/opt/build/repo/components/Layout/Toasts.vue').default,PlanChangeModal: require('/opt/build/repo/components/business/PlanChangeModal.vue').default,Sidebar: require('/opt/build/repo/components/Layout/Sidebar.vue').default,TopbarMobile: require('/opt/build/repo/components/Layout/TopbarMobile.vue').default})
