
import BasicLayout from '~/layouts/basic'
import pricingMixin from '~/mixins/pricing'
import VueSlider from 'vue-slider-component'

import { mapActions, mapState, mapWritableState } from 'pinia'
import { reverse, groupBy } from 'lodash'
import { useUiStore } from '~/store/ui'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'
import numeral from 'numeral'

export default {
    head() {
        return {
            title: 'SalesPop | Pricing',
        }
    },
    mixins: [pricingMixin],
    components: {
        BasicLayout,
        VueSlider,
    },
    data(){
        return {
            loaded: false,
            selectedPricingPlanId: null,
            showingExtraSessionsPicker: false,
            pickedExtraSessionsPricing: 200,
            showAnnualPricingPlans: false,
            showingAnnualPricingModal: false,
        }
    },
    mounted() {
        this.planChangeModal = false
        const fetchData = async () => {
            await this.loadCurrentSite()
            await this.loadPricingPlans()

            this.selectedPricingPlanId = this.currentSitePricingPlan?.id || this.selectablePricingPlans[0].id

            this.showAnnualPricingPlans = (this.currentSite.pricing_plan && this.isPricingPlanAnnual(this.currentSite.pricing_plan))

            this.loaded = true
        }

        fetchData()
    },
    methods: {
        ...mapActions(
            useSiteStore, [
                'loadCurrentSite',
                'updateSitePricingPlan',
                'changePricingPlanAutoUpgrade',
                'purchaseAdditionalSessions',
                'applyDiscountCode',
            ]),
        ...mapActions(usePricingStore, [
            'loadPricingPlans',
            'buildDiscountedPrice',
            'isPricingPlanAnnual',
            'isPricingPlanMonthly',
        ]),
        async handlePlanChange() {
            await this.updateSitePricingPlan(this.selectedPlan)
        },
        showCappedAmountModal() {
            this.showingAnnualPricingModal = true;
        },
        handleShowModalOrChangePlanImmediately(){
          if(!this.showingAnnualPricingModal && this.isPlanNeedMoreCappedAmount){
            this.showCappedAmountModal()
          } else {
            this.handlePlanChange()
          }
        },
        async handlePricingPlanAutoUpgradeChange() {
            await this.changePricingPlanAutoUpgrade({
                pricing_plan_auto_upgrade_enabled: this.currentSite.pricing_plan_auto_upgrade_enabled
            })
        },
        async handleAdditionalSessionsPaymentClick() {
          await this.purchaseAdditionalSessions(this.pickedExtraSessionsPricing)
          await this.loadCurrentSite()
          this.showingExtraSessionsPicker = false
        },
        showExtraSessionsPicker() {
          this.showingExtraSessionsPicker = true
        },
        async handleApplyDiscountCode() {
          await this.applyDiscountCode()
          await this.loadCurrentSite()
        },
        togglePricingPlans() {
           this.showAnnualPricingPlans = !this.showAnnualPricingPlans
        },
    },
    computed: {
        ...mapWritableState(useUiStore, ['loadingPreflight', 'planChangeModal']),
        ...mapState(useSiteStore, [
            'currentSite',
            'daysLeftOnFreePlan',
            'daysLeftOnAnnualPlan',
            'correctPlanName',
            'showFreePlanDaysLimitNotices',
            'loadingAdditionalSessionsPurchase',
            'loadingApplyingDiscountCode',
            'isOnUsageBasedPricing',
            'shopifySubscriptionCappedAmount',
            'showAnnualPricingPlanBanner',
            'planChangingInProgress',
        ]),
        ...mapWritableState(useSiteStore, [
          'discountCode',
        ]),
        ...mapState(usePricingStore, {
            usage: s => s.usage,
            planCopies: s => s.planCopies,
            reversePlanCopies: s => reverse([...s.planCopies]),
            plan: s => s.plan,
            plans: s => s.plans,
            pricingTableData: s => s.pricingTableData,
            popupPerks: s => s.popupPerks,
            currentCharge: s => s.currentCharge,
            pricingPlans: s => s.pricingPlans,
            selectablePricingPlans: s => s.selectablePricingPlans,
        }),
        currentSiteWithPricingPlan() {
          return this.currentSite?.utils?.with_pricing_plan
        },
        selectedPlanPrice() {
            if(!this.selectedPlan.price) { return }

            let format = '0,0.00'

            if (this.selectedPlan.price !== '0.0' && this.selectedPlan.price % 1 === 0) {
                format = '0,0'
            }

            return `$${numeral(this.selectedPlan.price).format(format)}`
        },
        discountedSelectedPlanPrice() {
            if(!this.selectedPlan.price) { return }

            if(this.showFreeSessionsLimit) return '$0.00'

            let price = this.buildDiscountedPrice(this.selectedPlan.price)

            return `$${numeral(price).format('0,0.00')}`
        },
        isFromMonthlyToAnnualPlan() {
            return this.currentSite.pricing_plan?.type === 'monthly' && this.selectedPlan.type === 'annual'
        },
        isSelectedPlan() {
            return plan => plan.id === this.selectedPricingPlanId
        },
        isPlanNeedMoreCappedAmount() {
            return (parseFloat(this.shopifySubscriptionCappedAmount) < parseFloat(this.selectedPlan.price)) || (this.isFromMonthlyToAnnualPlan && (parseFloat(this.shopifySubscriptionCappedAmount) < parseFloat(this.maxAnnualPricingPlan.price)))
        },
        formattedMaxSessions() {
            return plan => plan.unlimited ? 'Unlimited' : plan.max_sessions.toLocaleString('en-US', { maximumFractionDigits: 0 });
        },
        formattedPlanPrice() {
            return plan => this.currentSite.pricing_plan?.free && this.currentSitePricingPlan?.id === plan.id ? `$0.00` :
                          `$${Math.floor(plan.price).toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
        },
        formattedPlanMonthlyPrice() {
            return plan => `$${parseFloat(plan.monthly_price).toLocaleString('en-US')}`;
        },
        freeSessionsTerms() {
            return plan => this.currentSite.pricing_plan?.free && this.currentSitePricingPlan?.id === plan.id ?
                           `$${Math.floor(plan.price).toLocaleString('en-US')} after ${this.currentSite.pricing_plan.max_sessions} sessions` :
                           '&#8203;';
        },
        daysRemaining(){
            return this.currentSite?.utils?.show_days_remaining_free_plan_banner
        },
        perksByGroup(){
            return groupBy(this.pricingTableData, 'group')
        },
        formattedSelectedPlanMaxSessions() {
            return this.selectedPlan.unlimited ? 'Unlimited' : numeral(this.selectedPlan.max_sessions).format('0,0')
        },
        selectedPlan(){
            return this.selectablePricingPlans.find(plan => plan.id === this.selectedPricingPlanId) ||
                this.currentSitePricingPlan || {}
        },
        currentSitePricingPlanChosen() {
            return this.selectedPlan.id === this.currentSitePricingPlan?.id
        },
        showFreeSessionsLimit() {
            return this.nextPricingPlan && this.currentSitePricingPlanChosen
        },
        currentSitePricingPlan() {
            if (!this.currentSite.pricing_plan) return
            if (!this.currentSite.pricing_plan.free) return this.currentSite.pricing_plan

            return this.nextPricingPlan || this.currentSite.pricing_plan
        },
        additionalSessionsPricing() {
          return this.currentSite.additional_sessions_pricing || {}
        },
        selectedAdditionalSessionsPrice() {
          let selectedPrice = this.additionalSessionsPricing[this.pickedExtraSessionsPricing]
          let discountedPrice = this.buildDiscountedPrice(selectedPrice)
          let format = '0,0.00'

          if (discountedPrice !== '0.0' && discountedPrice % 1 === 0) {
            format = '0,0'
          }

          return `$${numeral(discountedPrice).format(format)}`
        },
        additionalSessionsSliderValues(){
          return Object.keys(this.additionalSessionsPricing).map(Number)
        },
        showBuyAdditionalSessions() {
          return this.isOnUsageBasedPricing && this.currentSite.discount_percentage < 100
        },
        additionalSessionsSliderConfig(){
            return {
                tooltip: 'none',
                dotSize: '20',
                dragOnClick: true,
                data: this.additionalSessionsSliderValues,
                adsorb: true,
                min: this.additionalSessionsSliderValues[0],
                max: this.additionalSessionsSliderValues[-1],
                marks: this.additionalSessionsSliderValues,
                processStyle: {
                    background: '#6F58FF'
                },
                railStyle: {
                    background: '#6F58FF40'
                },
                stepStyle: {
                    height: '12px',
                    width: '4px',
                    marginTop: '-4px',
                    backgroundColor: '#6F58FF40',
                    borderRadius: '8px',
                },
                stepActiveStyle: {
                    height: '12px',
                    width: '12px',
                    marginTop: '-4px',
                    marginLeft: '-4px',
                    backgroundColor: '#6F58FF',
                    borderRadius: '8px',
                },
            }
        },
        annualPricingPlans() {
            return this.selectablePricingPlans.filter((plan) => this.isPricingPlanAnnual(plan));
        },
        monthlyPricingPlans() {
            return this.selectablePricingPlans.filter((plan) => this.isPricingPlanMonthly(plan));
        },
        maxAnnualPricingPlan() {
            const maxPricePlan = this.annualPricingPlans.reduce((prev, current) => {
              return parseFloat(prev.price) > parseFloat(current.price) ? prev : current;
            })
            return maxPricePlan
        },
        pricingPlansToSelect() {
            return this.showAnnualPricingPlans ? this.annualPricingPlans : this.monthlyPricingPlans;
        },
        nextPricingPlan() {
            return this.pricingPlans.find(
              plan => plan.unique_id === this.currentSite.pricing_plan?.next_pricing_plan_unique_id
            )
        },
    }
}
