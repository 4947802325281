
import Loading from "~/layouts/loading.vue";
import { mapActions, mapState } from "pinia";
import { useSmartrmailEmbeddedStore } from "~/store/smartrmail/embedded";
import SalesPopLogo from "static/smartrmail-landing/sales-pop-logo.svg"
import SmartrmailLogo from "static/smartrmail-landing/smartrmail-logo.svg"

export default {
  components: {
    SalesPopLogo,
    SmartrmailLogo,
    Loading
  },
  mounted() {
    if(this.isEmbedded) {
      this.$router.push('/smartrmail/dashboard')
    }
  },
  computed: {
    ...mapState(useSmartrmailEmbeddedStore, ['isEmbedded']),
  },
  methods: {
    ...mapActions(useSmartrmailEmbeddedStore, ['fetchEmbeddedUrl', 'markSmartrmailEmbedded']),
    onGetStarted() {
      this.markSmartrmailEmbedded()
      this.$router.push('/smartrmail/dashboard')
    }
  }
}
