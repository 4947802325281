import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=4228f5f8&lang=pug"
import script from "./loading.vue?vue&type=script&lang=js"
export * from "./loading.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/opt/build/repo/components/EmptyState/Loading.vue').default})
