

export default {
  props: {
    appReviewed: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    enabledNotifications: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    smartrmailStepDone: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    relayPersonaStepDone: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    percentOfProgress() {
      const onboardingSteps = {
        daysTrial: 20,
        enableNotifications: this.enabledNotifications ? 20 : 0,
        smartrmail: this.smartrmailStepDone ? 20 : 0,
        relayPersona: this.relayPersonaStepDone ? 20 : 0,
        appReview: this.appReviewed ? 20 : 0,
      }

      return Object.values(onboardingSteps).reduce((acc, value) => acc + value)
    }
  },
  methods: {
    async handleRedirectToReviewPage(){
      await this.$track({
        name: 'dashboard_onboarding_card_button_clicked',
      })

      window.open('https://apps.shopify.com/shoppop?#modal-show=ReviewListingModal', '_blank').focus()
    },
    async handleBeaconChat(){
      await this.$track({
        name: 'dashboard_onboarding_card_link_clicked',
      })

      this.$helpScoutMessage({ subject: `Help with setup`, text: `Hi. I need help with app setup.` })
    }
  }
}
