
import Loading from "~/layouts/loading.vue";
import { mapActions, mapState } from "pinia";
import { useSmartrmailEmbeddedStore } from "~/store/smartrmail/embedded";
import SmartrmailReAuthModal from "~/components/Smartrmail/ReAuthModal.vue";
import { useSiteStore } from "~/store/site";

export default {
  name: 'SmartrmailEmbedded',
  components: { SmartrmailReAuthModal, Loading },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if(this.isEmbedded) {
      this.destroyHelpScoutBeacon()

      this.fetchEmbeddedUrl(this.path).then(() => {
        this.markSmartrmailEmbedded()
      })
    } else {
      this.$router.push('/smartrmail')
    }
  },
  destroyed() {
    this.setEmbeddedUrl(undefined)
    this.initializeHelpScoutBeacon()
  },
  methods: {
    ...mapActions(
      useSmartrmailEmbeddedStore,
      [
        'fetchEmbeddedUrl',
        'setEmbeddedUrl',
        'markSmartrmailEmbedded',
      ]
    ),
    ...mapActions(useSiteStore, ['initializeHelpScoutBeacon', 'destroyHelpScoutBeacon']),
  },
  computed: {
    ...mapState(useSmartrmailEmbeddedStore, ['isEmbedded', 'embeddedUrl']),
  }
};
